const morning = [
  '08.00 uur - 08.30 uur',
  '08.30 uur - 09.00 uur',
  '09.00 uur - 09.30 uur',
  '09.30 uur - 10.00 uur',
  '10.00 uur - 10.30 uur',
  '10.30 uur - 11.00 uur',
  '11.00 uur - 11.30 uur',
  '11.30 uur - 12.00 uur'
]
const afternoon = [
  '12.00 uur - 12.30 uur',
  '12.30 uur - 13.00 uur',
  '13.00 uur - 13.30 uur',
  '13.30 uur - 14.00 uur',
  '14.00 uur - 14.30 uur',
  '14.30 uur - 15.00 uur',
  '15.00 uur - 15.30 uur',
  '15.30 uur - 16.00 uur'
]
const evening = [
  '16.00 uur - 16.30 uur',
  '16.30 uur - 17.00 uur',
  '17.00 uur - 17.30 uur',
  '17.30 uur - 18.00 uur',
  '18.00 uur - 18.30 uur',
  '18.30 uur - 19.00 uur',
  '19.00 uur - 19.30 uur',
  '19.30 uur - 20.00 uur'
]

export { morning, afternoon, evening }
