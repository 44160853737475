var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('h4',{staticClass:"cv-day-number"},[_vm._v(_vm._s(_vm.formattedDate))]),(!_vm.data.length)?_c('div',{staticClass:"text-center pb-2"},[_c('strong',[_vm._v("Het lijkt er op dat er voor deze dag geen gegevens bekend zijn, controleer in het week of maand overzicht of dit een openings dag is")])]):_vm._e(),_vm._l((_vm.data),function(period){return _c('div',{key:period.BusinessHour,class:{
      'calendar-entry': true,
      'bg-darkred': period.PercentageUsedCheckPoints >= 100,
      'bg-danger':
        period.PercentageUsedCheckPoints >= 90 &&
        period.PercentageUsedCheckPoints <= 100,
      'bg-warning':
        period.PercentageUsedCheckPoints >= 70 &&
        period.PercentageUsedCheckPoints <= 90,
      'bg-success': period.PercentageUsedCheckPoints < 70
    }},[_vm._v(" "+_vm._s(period.Start)+" - "+_vm._s(period.End)+" "),_c('span',{staticClass:"pl-3"}),_vm._v(" "+_vm._s(period.PercentageUsedCheckPoints)+"% "+_vm._s(period.AvailableUsers)+" personen ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }